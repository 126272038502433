// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e4e7ec;
$primaryMain: #203461;
$primaryDark: #1c2f59;
$primary200: #909ab0;
$primary800: #132145;

// secondary
$secondaryLight: #fde8ef;
$secondaryMain: #203461;
$secondaryDark: #aa182c;
$secondary200: #f6a0bd;
$secondary800: #9a182c;

// success Colors
$successLight: #e3f8e8;
$success200: #8be09f;
$successMain: #17c13e;
$successDark: #14bb38;

// error
$errorLight: #e48784;
$errorMain: #d9534f;
$errorDark: #d54c48;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fdf5ea;
$warningMain: #f0ad4e;
$warningDark: #ec9c3d;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #0a0f23; // level 3
$darkPaper: #030614; // level 4

// dark 800 & 900
$darkLevel1: #070e13; // level 1
$darkLevel2: #12172f; // level 2

// primary dark
$darkPrimaryLight: #ecedf1;
$darkPrimaryMain: #606d88;
$darkPrimaryDark: #586580;
$darkPrimary200: #b0b6c4;
$darkPrimary800: #44506b;

// secondary dark
$darkSecondaryLight: #fde8ef;
$darkSecondaryMain: #ec407a;
$darkSecondaryDark: #ea3a72;
$darkSecondary200: #f6a0bd;
$darkSecondary800: #e42a5d;

// text variants
$darkTextTitle: #e4e8f7;
$darkTextPrimary: #d5d9e9;
$darkTextSecondary: #d8ddf0;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
